import barba from '@barba/core';
import { gsap } from "gsap/all";

import NavMobile from "./nav-mobile";
import Projects from "./projects";
import Slider from "./slider";
import ImageSlider from "./image-slider";
import Header from "./header";
import Reveal from "./reveal";

export default class BarbaJs {

  constructor() {
    const body = document.querySelector('body');
    const header = document.querySelector('.header');
    const headerNavigation = document.querySelector('.header--navigation');


    barba.init({
      timeout: 4000,

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active') || el.target == '_blank'),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Projects();
              new Header();
              new NavMobile();
              new Slider('.js--slider');
              new ImageSlider('.js--image-slider');
              new Reveal('.js--reveal');
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            headerNavigation.classList.add('fade-out');

            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },

          afterLeave(data) {
            window.scrollTo({
              top: 0,
              left: 0,
            });

            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },

          beforeEnter(data) {
            headerNavigation.classList.remove('fade-out');
            data.next.container.style.opacity = 0;
          },

          enter(data) {

            data.current.container.remove();

            // reinit JS
            setTimeout(() => {
              new Projects();
              new Header();
              new NavMobile();
              new Slider('.js--slider');
              new ImageSlider('.js--image-slider');
              new Reveal('.js--reveal');
            }, 1);

          },

          afterEnter(data) {
            // update header classes
            header.classList.toggle('bg-white', data.next.namespace !== 'home');
            header.classList.toggle('bg-transparent', data.next.namespace === 'home');
            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
            });
          }

        },
      ],

    });
  }

}
