import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"

export default class ImageSlider {

  constructor(selector) {
    this.sliders = document.querySelectorAll(selector);
    if (!this.sliders) {
      return;
    }

    for (let i = 0; i < this.sliders.length; ++i) {
      const slider = this.sliders[i];

      if (slider.dataset.sliderInitialized) {
        continue;
      }
      slider.dataset.sliderInitialized = true
      const sliderItems = slider.querySelectorAll('.slider--item');

      if (sliderItems.length > 1) {
        const options = {
          container: slider,
          mode: 'gallery',
          speed: slider.dataset.speed ? parseInt(slider.dataset.speed) : 2500,
          autoplay: false,
          autoplayTimeout: slider.dataset.timeout ? parseInt(slider.dataset.timeout) : 5000,
          autoplayButtonOutput: false,
          controls: true,
          nav: false,
        }
        const sliderInstance = tns(options);
      }
    }

  }

}
