export default class Projects {

  constructor() {
    const projects_target = document.querySelector('#projects-list');
    if (!projects_target) { return }

    const more_button = document.querySelector('#projects-load-more');

    const fetchMoreProjects = async () => {
      let next_page = parseInt(projects_target.dataset.nextPage);
      const url = `/projects.json/category:${projects_target.dataset.category}/page:${next_page}`;
      try {
        const response = await fetch(url);
        const { html, more, next_page } = await response.json();

        const last_child = projects_target.children[projects_target.children.length - 1];

        projects_target.insertAdjacentHTML('beforeend', html);
        projects_target.dataset.nextPage = next_page;

        if (last_child.nextElementSibling) {
          window.scrollTo({
            behavior: 'smooth',
            top:
              last_child.nextElementSibling.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              document.querySelector('.header').getBoundingClientRect().height - 25,
          });

          const newProjects = projects_target.querySelectorAll('.project-teaser.opacity-0');
          newProjects.forEach((element) => {
            element.classList.remove('opacity-0');
          });
        }

        more_button.classList.toggle('opacity-0', !more);
        more_button.classList.toggle('invisible', !more);
        more_button.classList.toggle('opacity-100', more);
        more_button.classList.toggle('visible', more);


      } catch (error) {
        console.log('Fetch error: ', error);
      }
    }

    more_button.addEventListener('click', () => {
      fetchMoreProjects()
    });

  }

}
